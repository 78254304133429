import { Box, Container, Typography } from "@mui/material";
import Terms from "../../Assets/Images/terms.png";
import classes from "./others.module.css";
import { useSelector } from "react-redux";
import { WhiteDownArrowIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Dropdown from "../Home/Dropdown";

function TermsOfUse2() {
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
      </Box>
   
      <Container>
					<h2 class="elementor-heading-title elementor-size-default">Terms &amp; Conditions</h2>

      <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Introduction</strong>&nbsp;</p>
<p>This website and associated services, including subdomains, mobile, tablet, and other smart device applications, and application program interfaces (hereinafter collectively referred to as “Gazam”), are owned, hosted, and operated by Gazam Associates Private Limited (“Gazam”). Gazam is a company incorporated in India under the Companies Act, with its registered office at H.No. 3-407/42-1, Hitech City, Road No.10, Near Royal Enfield Showroom, Mancherial, Telangana – 504208, India. These Terms, Privacy Policy, and Community Guidelines govern your use of the platform and constitute a legally binding agreement between Gazam and the User.&nbsp;</p>
<p>By accessing or using Gazam, you agree to be bound by these Terms. If you do not agree, you must stop using the platform immediately.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Modifications to Terms</strong>&nbsp;</p>
<p>Gazam reserves the right to amend or modify these Terms at any time, and such modifications shall become effective immediately upon posting on the platform. You are encouraged to review the Terms periodically to stay updated. Your continued use of the platform shall be deemed conclusive proof of your acceptance of the amended Terms. Gazam may suspend the operation of the platform for support, technical upgrades, maintenance, or other reasons without notice. Should you use the platform in a manner inconsistent with these Terms, Gazam reserves the right to terminate or restrict your access and pursue any other necessary legal remedies.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Definitions</strong>&nbsp;</p>
<ul>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Subscriber/User</strong>: Any individual or entity who subscribes to Gazam’s services by creating an account (whether free or paid), to whom access is restricted via a username and password.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Advertiser</strong>: A Subscriber who lists properties on the platform.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Visitor</strong>: A person who visits the platform without needing to create an account, accessing non-restricted sections of the platform.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Service(s)</strong>: The online property listing, search tools, and advertisement services provided by Gazam through the platform.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Amendments to the Terms</strong>&nbsp;</p>
<p>Gazam reserves the right to modify these Terms at any time, and such modifications will take effect upon posting on the platform. Users are encouraged to review these Terms periodically. Continued use of the platform constitutes acceptance of the revised Terms.&nbsp;</p>
<ol>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Use of Services</strong>&nbsp;</li>
</ol>
<ul>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Property Listings</strong>: Subscribers are responsible for ensuring that all property listings are accurate, lawful, and up-to-date. Listings must not contain any misleading information. Gazam does not independently verify the accuracy of these listings.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">RERA Compliance</strong>: All users listing properties must comply with the Real Estate (Regulation and Development) Act, 2016 (RERA) and must have all necessary licenses, approvals, and permits for properties listed under this Act.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Third-Party Content</strong>: The platform may include content or links from third-party sources, including video content for informational purposes. Gazam is not responsible for the accuracy or reliability of third-party content.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Account Responsibility</strong>&nbsp;</p>
<p>Subscribers must provide accurate information during the registration process. Subscribers are responsible for maintaining the confidentiality of their account credentials and for all activities conducted under their account. Gazam may suspend or terminate accounts found in violation of these Terms.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Intellectual Property</strong>&nbsp;</p>
<ul>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Ownership</strong>: All intellectual property rights on the platform, including but not limited to content, designs, and trademarks, are owned or licensed by Gazam. Users are prohibited from copying, reproducing, or distributing content from the platform without Gazam’s written consent.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">License</strong>: Users are granted a limited, non-exclusive, revocable license to access and use the platform for personal or business purposes, provided that usage adheres to these Terms.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Restrictions</strong>&nbsp;</p>
<p>Users agree not to:&nbsp;</p>
<ul>
<li>Post inaccurate or false property details.&nbsp;</li>
<li>Use the platform to engage in fraudulent activities or for any illegal purposes.&nbsp;</li>
<li>Scrape, extract, or download data from the platform using automated systems.&nbsp;</li>
<li>Impersonate others or misrepresent their affiliation with Gazam.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Submission and Administration of Listings/Advertisements</strong>&nbsp;</p>
<ul>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">User Responsibilities</strong>: Users agree not to submit property descriptions, images, financial data, or contact information without having the necessary legal rights and authorizations from the property owner or power-of-attorney holder, including permission from photographers or copyright holders of any associated media.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Gazam’s Role</strong>: Gazam does not own or verify the content submitted by users and acts solely as a medium for information exchange. Users are responsible for verifying the accuracy of their listings and complying with all applicable legal requirements, including the Real Estate (Regulation and Development) Act, 2016 (RERA), for properties listed under this regulation.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Accuracy and Compliance</strong>: Users must ensure that all property listings are accurate and lawful. Properties listed must disclose all necessary details such as property dimensions, title ownership, encumbrances, and compliance with relevant laws. Gazam may request supporting documents to verify these claims and may remove listings found to be inaccurate or non-compliant.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Modifications and Removal</strong>: Gazam reserves the right to modify or remove listings at its sole discretion. Additionally, the user grants Gazam permission to display, search, and share the property listings, which can be accessed, downloaded, or copied by other users of the platform.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Verification</strong>: Gazam’s “verified” tag on a property indicates verification of its existence as per the information provided but does not verify legal documentation, ownership details, or the property’s financial or legal status. Users must independently verify such details before engaging in property transactions.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Payment Terms</strong>&nbsp;</p>
<ul>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Advance Payments</strong>: Payments for services offered by Gazam are required on a 100% advance basis. Once paid, these amounts are non-refundable unless otherwise stated. Refunds, if any, are at Gazam’s sole discretion, and no guarantees are made regarding the timeliness or accuracy of refunds reaching users’ bank accounts.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Set-Off</strong>: Gazam reserves the right to offset any outstanding amounts payable by the user against any future services or agreements.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Payment Gateway</strong>: For online transactions, Gazam uses third-party payment gateways and does not store credit card information. Once the transaction is complete, payment details are not accessible to Gazam, ensuring secure processing.&nbsp;</li>
<li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Liability Disclaimer</strong>: Gazam is not liable for any errors, omissions, or disclosure of payment details during online transactions. Users are advised to ensure all transactions are processed securely.&nbsp;</li>
</ul>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Refund in Failed Transactions</strong>&nbsp;</p>
<p>Gazam makes no guarantees regarding the timeliness or accuracy of refunds due to the multiple parties involved in online transactions. Refunds for wrong or objectionable property content will be at Gazam’s discretion.&nbsp;</p>
<p>Users are responsible for ensuring proper use of the platform in accordance with the terms. Misuse of the platform or violation of terms may result in termination or blocking of access. Gazam reserves the right to pursue further legal remedies for misuse if necessary.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Video and Photo Community Guidelines</strong>&nbsp;</p>
<p>Users submitting video or photo content must ensure that it is:&nbsp;</p>
<ul>
<li>Original and free from any copyright issues.&nbsp;</li>
<li>Does not contain personally identifiable or inappropriate information.&nbsp;</li>
<li>Not obscene or detrimental to any community or segment.&nbsp;</li>
</ul>
<p>All submitted video and photo content will go through multiple levels of screening before being published. Violations may result in removal, and users can report content for review. Final discretion on content lies with Gazam. Gazam may utilize third-party services, such as YouTube, for hosting videos, and all such use must comply with third-party terms of service.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">User Data and Privacy</strong>&nbsp;</p>
<p>Gazam is committed to protecting user data in compliance with the Information Technology Act, 2000, and related data protection rules. Personal data collected during registration or through property listings is handled according to the Privacy Policy. Users must treat all information obtained through Gazam as proprietary and ensure its confidentiality.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Intellectual Property Rights</strong>&nbsp;</p>
<p>All intellectual property on the platform, including trademarks and content, is owned or licensed by Gazam and its affiliates. Unauthorized use of this content is strictly prohibited. Gazam reserves the right to terminate access for users infringing on intellectual property rights.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Restrictions/Prohibitions</strong>&nbsp;</p>
<p>The following actions will constitute misuse of Gazam’s platform and are strictly prohibited:&nbsp;</p>
<ul>
<li>Utilizing the platform in ways that impair the functionality of Gazam and violate applicable laws, including RERA.&nbsp;</li>
<li>Copying, extracting, sharing, or modifying content for purposes other than personal or internal use.&nbsp;</li>
<li>Using any automated systems (e.g., bots, crawlers) to scrape or access Gazam’s data without permission.&nbsp;</li>
<li>Gaining unauthorized access to Gazam’s platform, systems, or networks.&nbsp;</li>
<li>Engaging in activities like spamming, impersonation, or tampering with security features.&nbsp;</li>
</ul>
<p>Users must refrain from:&nbsp;</p>
<ul>
<li>Engaging in unlawful or harmful activities, including harassment or distribution of harmful content.&nbsp;</li>
<li>Circumventing any limits, such as search or captcha limits.&nbsp;</li>
<li>Hosting, transmitting, or distributing illegal or offensive materials that violate any local, national, or international laws.&nbsp;</li>
</ul>
<p>Violations of these prohibitions may result in account termination or legal consequences.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Links to Third-Party Websites</strong>&nbsp;</p>
<p>Gazam may provide links to external websites, but it assumes no responsibility for the content, services, or security of these third-party sites.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Disclaimer and Warranties</strong>&nbsp;</p>
<p>Gazam’s platform is provided “as is” and “as available,” without warranties of any kind, either express or implied. Gazam disclaims all warranties related to merchantability, fitness for a particular purpose, accuracy, or non-infringement. Users are solely responsible for verifying property details and ensuring any investments or decisions are made at their own risk.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">17. Limitation of Liability</strong>&nbsp;</p>
<p>Gazam is an intermediary under the provisions of the Information Technology Act, 2000, and does not assume liability for the accuracy, completeness, or legality of the property listings or third-party content. Gazam is not responsible for any loss, damage, or legal issues arising from the use of the platform, including property transactions, inaccuracies in listings, or third-party links. In no event shall Gazam be liable for any indirect, consequential, exemplary, or punitive damages.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">18. Termination of Services</strong>&nbsp;</p>
<p>Gazam reserves the right to suspend or terminate a User’s account at any time without notice for violations of these Terms, applicable laws, or actions that harm the platform’s integrity. Upon termination, the User’s right to use the platform will immediately cease, and any outstanding obligations (such as payment dues) will survive the termination of the agreement.&nbsp;</p>
<p>Users may terminate their accounts by providing prior written notice to Gazam. Upon termination, any unused services or payments made are non-refundable unless otherwise agreed by Gazam. Gazam reserves the right to refuse service to any individual or entity at its sole discretion.<br/></p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">19. Indemnification</strong>&nbsp;</p>
<p>Users agree to indemnify, defend, and hold Gazam, its affiliates, subsidiaries, officers, directors, employees, and agents harmless from any claims, actions, liabilities, damages, or losses arising from the User’s breach of these Terms, violation of any applicable laws, or misuse of the platform. This includes any legal costs and expenses incurred due to a User’s actions.&nbsp;</p>
<p>Gazam reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by the User, in which case the User agrees to cooperate with Gazam in asserting any available defenses.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">20. Governing Law</strong>&nbsp;</p>
<p>These Terms shall be governed by and construed in accordance with the laws of the State of Telangana, India, without regard to its conflict of law provisions. Any disputes arising out of or related to these Terms shall be subject to the exclusive jurisdiction of the courts in Telangana, India.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">21. Arbitration</strong>&nbsp;</p>
<p>In the event of any disputes, disagreements, or claims arising out of or relating to the use of the platform or these Terms, the parties shall first attempt to resolve the matter amicably through mutual discussions. If such disputes cannot be resolved, they will be settled by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in Mancherial, Telangana, and the decision of the arbitrator(s) shall be final and binding on the parties.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">22. Force Majeure</strong>&nbsp;</p>
<p>Gazam shall not be liable for any failure to perform any of its obligations under these Terms due to circumstances beyond its reasonable control, including but not limited to natural disasters, acts of terrorism, war, strikes, government regulations, internet outages, or other unforeseen events.&nbsp;</p>
<p>In such cases, Gazam may suspend its services for the duration of the force majeure event and shall resume its obligations once the event has been resolved or conditions allow for normal service to continue.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">23. Notices</strong>&nbsp;</p>
<p>All notices, requests, or communications required or permitted under these Terms shall be in writing and shall be deemed to have been duly delivered when sent via email, personal delivery, or by registered or certified mail to Gazam’s registered office or to the contact details provided by the User.&nbsp;</p>
<p>Users are responsible for ensuring that their contact information is accurate and up-to-date to receive notifications from Gazam. Failure to do so may result in missed notifications or updates, for which Gazam will not be liable.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">24. Entire Agreement</strong>&nbsp;</p>
<p>These Terms, along with the Privacy Policy and Community Guidelines, constitute the entire agreement between the User and Gazam regarding the use of the platform. These Terms supersede any prior agreements, communications, or representations, whether oral or written, regarding the subject matter herein.&nbsp;</p>
<p>No modification or amendment to these Terms shall be binding unless made in writing and duly signed by both parties or posted by Gazam on the platform. In the event that any provision of these Terms is found to be unenforceable or invalid under applicable law, the remaining provisions shall continue in full force and effect.&nbsp;</p>
<p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">25. Contact Information</strong>&nbsp;</p>
<p>For any questions, issues, or complaints regarding these Terms or the services offered by Gazam, you may contact:&nbsp;<a class="x-el x-el-a c1-1 c1-2l c1-2t c1-10 c1-11 c1-2u c1-2v c1-1j c1-b c1-2w c1-1c c1-2x c1-2y c1-2z c1-30" href="mailto:info@gazam.in" target="_blank" rel="noopener">info@gazam.in</a></p>				
      </Container>
    </Box>
  );
}

export default TermsOfUse2;
