import { Box, Container, Typography } from "@mui/material";
import Terms from "../../Assets/Images/terms.png";
import classes from "./others.module.css";
import { useSelector } from "react-redux";
import { WhiteDownArrowIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Dropdown from "../Home/Dropdown";

function PrivacyPolicy2() {
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Box>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
      
      </Box>
    
      <Container>
        <Box py={3}>
          <h3 style={{ fontWeight: "800" }}>Privacy Policy</h3>
          <p>At&nbsp;<strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Gazam Associates Private Limited (“Gazam”)</strong>, we are committed to respecting your privacy and ensuring the protection and appropriate management of any personal information you share with us. This Privacy Policy (“Policy”) applies to our website, subdomains, mobile applications, and associated services (collectively referred to as “Platform”). This Policy outlines how we collect, use, disclose, and protect your data while adhering to the&nbsp;<strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011</strong>&nbsp;under the&nbsp;<strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Information Technology Act, 2000</strong>&nbsp;and other applicable Indian laws.&nbsp;</p>
          <p>By accessing or using Gazam’s platform or services, you consent to the collection, use, and processing of your personal data as described in this Policy.&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">1. Information We Collect</strong>&nbsp;</p>
          <p>We collect various types of personal information to provide and improve our services:&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">A. Information You Provide Directly:</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Registration Data</strong>: Name, email address, phone number, password, and any identification documents like PAN, Aadhaar, Voter ID, etc.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Property Listings</strong>: Property type, address, dimensions, photos, pricing, and additional features like amenities or furnishing details.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Payment Information</strong>: We use third-party payment gateways for transactions, and while we may receive transaction details, we do not store sensitive financial information like card details.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Communication Data</strong>: Emails, chats, customer service inquiries, and any feedback provided through surveys or forms.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">B. Automatically Collected Information:</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Device Information</strong>: Operating system, IP address, device type, browser details, unique device identifiers, etc.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Usage Data</strong>: Browsing history, search queries, clickstream data, and interaction with the Platform’s features.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Location Information</strong>: General or precise geographic data from your device or IP address.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">C. Information from Third Parties:</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Marketing &amp; Partners</strong>: Information from affiliates or service providers offering complementary services.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Social Media Login</strong>: If you log in via third-party services like Google or Facebook, we may collect your profile data (name, email, etc.).&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">2. How We Use Your Information</strong>&nbsp;</p>
          <p>Gazam uses your personal information to provide and enhance our services, ensure compliance, and meet regulatory obligations. Specific uses include:&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Account Management</strong>: Facilitate login, manage profiles, and enable communication between property owners and potential buyers/tenants.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Property Listings</strong>: To list your properties, respond to inquiries, and connect with interested parties.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Marketing</strong>: Sending promotional material, offers, or updates about new features and services, only with your consent.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Improvement of Services</strong>: Conducting analytics to improve user experience, customizing search results, and optimizing service features.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Fraud Prevention</strong>: Verify user identities, monitor suspicious activities, and ensure secure transactions.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Compliance with Legal Requirements</strong>: To comply with government and law enforcement requests, enforce legal claims, or meet statutory obligations.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">3. Sharing of Information</strong>&nbsp;</p>
          <p>Gazam may share your personal data with:&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Service Providers</strong>: External vendors who assist in providing services (e.g., payment processors, marketing partners, cloud storage providers).&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Other Platform Users</strong>: Your details (e.g., phone number, email) may be shared with other users to facilitate property transactions.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Legal &amp; Regulatory Authorities</strong>: In response to legal requirements, such as subpoenas, investigations, or government requests.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Corporate Transactions</strong>: In the event of mergers, acquisitions, or restructures, your data may be transferred to a successor entity.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Marketing Partners</strong>: We may share non-sensitive data with third-party partners to display targeted ads relevant to your preferences.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">4. Data Retention and Security</strong>&nbsp;</p>
          <p>We retain your personal data for as long as needed to fulfill the purposes outlined in this Privacy Policy or to comply with legal obligations. Once the data is no longer needed, we securely delete or anonymize it.&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Security Measures</strong>:&nbsp;</p>
          <p>Gazam has implemented stringent physical, electronic, and administrative measures to protect your data from unauthorized access, breaches, or loss. These include encryption, secure storage practices, and regular security audits. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">5. Your Rights</strong>&nbsp;</p>
          <p>You have the following rights regarding your personal data:&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Access &amp; Correction</strong>: You can request access to or correction of the personal data we hold about you.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Data Portability</strong>: Request the transfer of your data to another service provider.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Withdrawal of Consent</strong>: You may withdraw your consent for certain uses of your data (e.g., marketing), but this may impact your ability to use specific features.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Deletion</strong>: Request deletion of your data under applicable laws, but some data may be retained for legal purposes.&nbsp;</li>
          </ul>
          <p>For any of these requests, please contact us using the details in the Grievance section.&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">6. Data Retention and Protection</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Data Retention</strong>: We retain your personal data only for the period necessary for the purposes outlined in this policy or as required under legal obligations such as the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Security Measures</strong>: Our platform uses encryption technologies, secure servers, and firewalls to ensure the confidentiality of your data. We comply with recognized security standards to prevent unauthorized access, disclosure, and breaches.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Breach Notification</strong>: In the event of a security breach, we will promptly inform affected users and relevant authorities, taking corrective actions in line with best practices and applicable legal frameworks.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">7. Third-Party Websites, Apps, and Services</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Third-Party Links</strong>: The platform may link to third-party websites or services. We encourage you to review their privacy policies before engaging, as their practices may differ from ours.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Independent Data Control</strong>: These third parties operate independently, and we are not responsible for their privacy practices or content. We recommend careful review of their policies before sharing personal data.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Data Sharing for Service Delivery</strong>: We may share personal data with third-party service providers who assist in service delivery. This data sharing adheres to necessary legal standards and data protection principles.&nbsp;</li>
          </ul>
          <p>&nbsp;</p>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">8. Children’s Privacy</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Age Restriction</strong>: The platform is not intended for users under 18 years of age. We do not knowingly collect or solicit personal data from children.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Parental Supervision</strong>: For users under 18, we encourage parental guidance while using the platform. If we become aware that personal data of minors has been collected, we will take steps to delete this data in compliance with privacy regulations.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Data Removal</strong>: Upon discovering that a child’s data has been collected without parental consent, we will immediately remove such information in line with our legal obligations.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">9. Changes to the Privacy Policy</strong>&nbsp;</p>
          <ul>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Policy Amendments</strong>: We reserve the right to update or modify this Privacy Policy in accordance with legal requirements or changes in our practices. Updates will be posted on the platform and will take effect immediately.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">User Notification</strong>: In the event of significant changes to the policy, users will be notified through email or platform notifications to ensure transparency.&nbsp;</li>
            <li><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">Periodic Review</strong>: We recommend that users periodically review this policy to remain informed of any updates. Continued use of the platform constitutes acceptance of these terms.&nbsp;</li>
          </ul>
          <p><strong class="x-el x-el-span c1-2k c1-2l c1-b c1-2m c1-1k c1-2n c1-2o">10. Grievance Redressal</strong>&nbsp;</p>
          <p>We are committed to resolving any concerns or complaints related to the processing of your personal data promptly and fairly.&nbsp;</p>
          <p>If you have any grievances, questions, or concerns regarding your personal data or this Privacy Policy, you may contact our Grievance Officer at&nbsp;<a class="x-el x-el-a c1-1 c1-2l c1-2t c1-10 c1-11 c1-2u c1-2v c1-1j c1-b c1-2w c1-1c c1-2x c1-2y c1-2z c1-30" href="mailto:info@gazam.in" target="_blank" rel="noopener">info@gazam.in</a></p>								

    </Box>
      </Container >
    </Box >
  );
}

export default PrivacyPolicy2;
